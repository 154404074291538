/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError, formatUserType, onHide, validatePhone } from "../../../../../utils/utils";
import {
	useAddOrUpdateUserMutation,
	useDeleteMultipleUsersMutation,
	useLazySendDeleteUserCodeQuery,
	useToggleActiveUserMutation,
} from "../../../../../utils/api/user/user.api";
import { IUser, UserFormData, UserType } from "../../../../../utils/api/user/user.type";
import toast from "react-hot-toast";
import { useCountdownTimer } from "../../../../../utils/hooks";
import { QueryError } from "../../../../../utils/type";
import { alphaRegex, alphaSpaceRegex } from "../../../../../utils/constantes";
import { useGetSpecialiteQuery } from "../../../../../utils/api/config/config.api";

function useAddOrEditUser(
	resetItem: () => void,
	typeUser: UserType,
	modalId: string,
	item?: IUser
) {
	const [code, setCode] = useState("");
	const validationSchema = yup.object().shape({
		type_user: yup.string().default(typeUser),
		nom: yup
			.string()
			.required()
			.matches(alphaRegex, "Nom doit contenir que des lettres")
			.min(2, "Nom doit être au minimum 2 lettes")
			.label("Nom"),
		prenom: yup
			.string()
			.required()
			.matches(alphaSpaceRegex, "Prénom doit contenir que des lettres")
			.min(2, "Prénom doit être au minimum 2 lettes")
			.label("Prénom"),
		telephone: validatePhone(yup, code).required(),
		email: yup.string().email().required().label("Email"),
		nom_pharmacie: yup.string().when("type_user", {
			is: UserType.pharmacien,
			then: yup.string().required().label("Nom de la pharmacie"),
		}),
		adresse: yup.string().required().label("Adresse"),
		specialite: yup.string().when("type_user", {
			is: UserType.medecin,
			then: yup.string().required().label("Specialité"),
		}),
		code_postal: yup
			.string()
			.test("len", "Le code postal doit contenir exactement 5 caractères", (val) => {
				if (val) {
					return val.length === 5;
				}
				return true;
			})
			.label("Code postal")
			.nullable(),
	});

	const [phone, setPhone] = useState<string>();
	const [picture, setPicture] = useState<File>();
	const [country, setCountry] = useState<any>("");
	const [city, setCity] = useState<{ label: ""; value: "" }>();
	const [options, setOptions] = useState<{ label: ""; value: "" }[]>([]);
	const [cities, setCities] = useState<any>();
	const [optionsSpecialite, setOptionsSpecialite] = useState<{ value: number; label: string }[]>(
		[]
	);
	const [selected, setSelected] = useState<{ label: string; value: number }>();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
		control
	} = useForm<UserFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			user_type: typeUser,
		},
	});
	const { data = { results: [] }, isLoading: isLoad } = useGetSpecialiteQuery({
		limit: 1000,
	});

	const [addOrUpdate, { isLoading }] = useAddOrUpdateUserMutation();

	const onChangePhone = (phone: string, country: any) => {
		setCode(country?.countryCode);
		setPhone(phone);
		setValue("telephone", `+${phone}`);
	};

	const handleChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			const file = e.currentTarget.files[0];
			setPicture(file);
			setValue("avatar", file);
		}
	};

	const handleChangeAdress = (value) => {
		console.log(value);
		setValue("adresse", value?.rue);
		setValue("longitude", value?.longitude);
		setValue("latitude", value?.latitude);
	};

	const handleCountrySelect = (option: any) => {
		// console.log({ option, cities });
		setCountry(option);
		setValue("pays", option?.name);
		const data = cities
			?.filter((item) => item?.country_code === option?.iso2)
			?.map((item) => {
				return {
					label: item?.name,
					value: item?.name,
				};
			});
		console.log({ data });
		setOptions(data);
	};

	const handleCitySelect = (option: any) => {
		// setCity(option);
		console.log({ option });
		setValue("ville", option?.value);
		setCity(option);
	};


	const onSelectSpecialite = (e) => {
		setSelected(e);
		setValue("specialite", e?.value);
	};

	const fetchCities = async () => {
		try {
			const response = await fetch(
				"https://raw.githubusercontent.com/mbaye19/country-data/main/Cities.json"
			); // Replace with your API endpoint
			const data = await response.json();
			setCities(data);
		} catch (error) {
			console.error("Error:", error);
		}
	};


	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		let fields: (keyof UserFormData)[] = [
			"nom",
			"prenom",
			"telephone",
			"email",
			"adresse",
			"code_postal",
			"nom_pharmacie",
			"pays",
			"ville",
			"rue",
			"specialite",
		];
		if (item?.id) {
			console.log({ item });
			for (let field of fields) {
				setValue(field, item[field]);
			}
			setPhone(item?.telephone);
			setCountry(item?.pays);
			if (item?.specialite) {
				setSelected({
					label: item.specialite?.nom,
					value: item.specialite?.id,
				});
				setValue("specialite", item.specialite?.id);
			}
		} else {
			for (let field of fields) {
				setValue(field, "");
			}
		}
	}, [item]);

	useEffect(() => {
		if (options?.length === 0) {
			fetchCities();
		}
	}, [options]);

	useEffect(() => {
		if (data?.results?.length) {
			setOptionsSpecialite(
				data?.results?.map((el) => {
					return {
						label: el?.nom,
						value: el?.id,
					};
				})
			);
		}
		else {
			setOptionsSpecialite([]);
			setSelected(undefined);
		}
	}, [data]);

	const handleReset = () => {
		setPhone("");
		setPhone("+221");
		setPicture(undefined);
		reset();

		resetItem();
	};

	const onSubmit = async (data: UserFormData) => {
		console.log({ data });
		const fd = new FormData();
		for (let key of Object.keys(data)) {
			const value = data[key];
			fd.append(key, value);
		}
		const res = await addOrUpdate({
			slug: item?.slug,
			data: fd,
		});
		if ("data" in res) {
			let message = `${typeUser === UserType.medecin ? "Médecin" : typeUser === UserType?.pharmacien ? "Pharmacien" : "Patient"
				} ${item ? "modifié" : "ajouté"} avec succès!`;
			toast.success(message);
			handleReset();
			onHide(modalId);
			setPhone("");
			setCountry("");
		} else if ("error" in res) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const err = res.error as any;
			let message = err?.data?.message
				? err?.data?.message
				: err?.data?.email
					? "Un utilisateur avec cet email existe déjà"
					: err?.data?.telephone
						? "Un utilisateur avec ce numero de téléphone existe déjà"
						: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`;
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		phone,
		onChangePhone,
		picture,
		handleChangePicture,
		setPhone,
		handleChangeAdress,
		country,
		city,
		handleCountrySelect,
		handleCitySelect,
		options,
		handleReset,
		optionsSpecialite,
		onSelectSpecialite,
		selected,
		Controller,
		control,
		isLoad
	};
}

export function useToggleArchiveUser(user: IUser) {
	const [editData] = useAddOrUpdateUserMutation();
	const typeUser =
		user?.user_type === UserType.medecin
			? "médecin"
			: user?.user_type === UserType.patient
				? "patient"
				: "pharmacien";
	const onArchive = async () => {
		let data = {
			is_archive: !user.is_archive,
		};
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${user?.is_archive === true ? "activer" : "désactiver"
				} ce ${typeUser}?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.blue,
			preConfirm: () => {
				return editData({ slug: user.slug, data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				toast.success(
					`${formatUserType(user?.user_type)} ${result?.value?.data?.is_archive ? "désactivé" : "activé "
					} avec succèss!`
				);
			}
		});
	};
	return onArchive;
}

export function useToggleStatutUser(user: IUser) {
	const [disableUser] = useToggleActiveUserMutation();
	const typeUser =
		user?.user_type === UserType.medecin
			? "médecin"
			: user?.user_type === UserType.patient
				? "patient"
				: "pharmacien";
	const onArchive = async () => {
		await Swal.fire({
			title: `Êtes-vous sure de vouloir  ${!user?.is_active === true ? "activer" : "désactiver"
				} ce ${typeUser}?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.blue,
			preConfirm: () => {
				return disableUser({ slug: user.slug, is_active: user?.is_active });
			},
			allowOutsideClick: () => !Swal.isLoading(),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				toast.success(
					`${formatUserType(user?.user_type)} ${result?.value?.data?.is_active ? "désactivé" : "activé "
					} avec succèss!`
				);
			}
		});
	};
	return onArchive;
}

export default useAddOrEditUser;

export function useDeleteMultipleUsers(users: number[], reset: () => void) {
	const [sendCode, { isLoading }] = useLazySendDeleteUserCodeQuery();
	const [deleteMultiple, { isSuccess, isError, error }] = useDeleteMultipleUsersMutation();
	const { isTimeActive, stopTimer, startTimer } = useCountdownTimer(2);

	function Popup() {
		Swal.fire({
			title: "Entrez le code que vous avez reçu par mail",
			icon: "info",
			showCancelButton: true,
			confirmButtonText: "Supprimer",
			cancelButtonText: "Fermer",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.blue,
			confirmButtonColor: Color.danger,
			input: "password",
			inputAttributes: {
				autocapitalize: "off",
			},
			allowOutsideClick: false,
			preConfirm: (inputValue) => {
				console.log({ users });
				// return deleteMultiple({ users: users });
				if (inputValue) {
					return deleteMultiple({
						users: users,
						code: inputValue,
					});
				}
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("1", { users });
			}
		});
	}

	const onDelete = useCallback(async () => {
		if (isTimeActive) {
			Popup();
		} else {
			const res = await sendCode();
			if ("data" in res) {
				startTimer();
				Popup();
			} else {
				toast.error("Erreur d'envoie de code");
			}
		}
	}, []);

	useEffect(() => {
		if (isSuccess) {
			toast.success("utililisateurs supprimés avec succès");
			reset();
			stopTimer();
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as QueryError;
			const message =
				err?.data?.message ?? `Une erreur de status ${err?.status} est survenue!`;
			toast.error(message);
		}
	}, [isError]);

	return {
		onDelete,
		isLoading,
	};
}

import React from "react";
import { BtnSubmit } from "../../../common/Button";
import { AdminInput, FormError, RequiredStar } from "../../../common/Input";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import useAddOrEditUser from "../ListeUsers/hooks/useAddOrEditUser";
import PhoneInput from "react-phone-input-2";
import { IUser, UserType } from "../../../../utils/api/user/user.type";
import { PreviewImage } from "../../../common/PreviewImage";
import GoogleInput from "../../../common/GoogleInput";
import { CountrySelector } from "volkeno-react-country-state-city";
import "volkeno-react-country-state-city/dist/index.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const modalId = "AddMedecinModal";
const AddMedecinModal = ({
	item,
	resetItem,
}: {
	resetItem: () => void;
	item?: IUser;
}) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		phone,
		onChangePhone,
		picture,
		handleChangePicture,
		setPhone,
		handleChangeAdress,
		country,
		handleCountrySelect,
		handleReset,
		optionsSpecialite,
		Controller,
		control,
		onSelectSpecialite,
		selected,
		isLoad
	} = useAddOrEditUser(resetItem, UserType.medecin, modalId, item);
	const reset = () => {
		resetItem();
		setPhone("");
	};
	const animatedComponents = makeAnimated();
	return (
		<div className="modal-dialog modal-lg">
			<div className="modal-content">
				<div className="modal-header py-2 flex-m">
					<h5 className="admin-modal-title text-start">
						{item ? "Modifier le " : "Ajouter un"}{" "}
						médecin
					</h5>
					<CloseModalBtn
						onClick={() => {
							reset();
							handleReset();
						}}
					/>
				</div>
				<div className="modal-body">
					<div className="container-form">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-6">
									<AdminInput
										label="Nom du médecin"
										id="nom"
										type="text"
										placeholder="Nom médecin"
										{...register("nom")}
										error={errors?.nom}
										required
									/>
								</div>
								<div className="col-md-6">
									<AdminInput
										label="Prénom du médecin"
										id="prenom"
										type="text"
										placeholder="Prénom médecin"
										{...register(
											"prenom"
										)}
										error={
											errors?.prenom
										}
										required
									/>
								</div>
								<div className="col-md-6">
									<AdminInput
										label="Adresse mail"
										required
										id="email"
										type="text"
										placeholder="Adresse mail"
										{...register(
											"email"
										)}
										error={
											errors?.email
										}
										inputMode="text"
									/>
								</div>
								<div className="col-md-6">
									<label
										htmlFor="nombre_stock"
										className="form-label form-label-modal-custom"
									>
										Spécialité du médecin
										<RequiredStar />
									</label>
									<Controller
										name="specialite"
										control={control}
										render={({ field }) => {
											return (
												<Select
													{...field}
													closeMenuOnSelect={true}
													components={animatedComponents}
													options={optionsSpecialite}
													value={selected ? selected : null}
													onChange={(val) => onSelectSpecialite(val)}
													className=""
													isLoading={isLoad}
												/>
											);
										}}
									/>
									<FormError error={errors?.specialite} />
								</div>
								<div className="col-md-6">
									<div className="mb-3">
										<label
											htmlFor="telephone"
											className="form-label form-label-modal-custom"
										>
											Numéro de
											téléphone
											<RequiredStar />
										</label>
										<PhoneInput
											defaultMask={
												".. ... .. .."
											}
											containerClass="form-control auth-form-control mb-3 p-0"
											inputClass="form-control form-control-modal-custom"
											country={"sn"}
											placeholder=""
											inputProps={{
												name: "telephone",
												required: true,
											}}
											prefix="+"
											value={phone}
											onChange={(
												phone,
												country
											) =>
												onChangePhone(
													phone,
													country
												)
											}
											inputStyle={{
												width: "100%",
											}}
										/>
										<FormError
											error={
												errors
													?.telephone
													?.message
											}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<label
										htmlFor="adress"
										className="form-label form-label-modal-custom"
									>
										Adresse médecin
										<RequiredStar />
									</label>
									<GoogleInput
										value={
											item?.adresse ??
											""
										}
										onChange={
											handleChangeAdress
										}
										className="form-control form-control-modal-custom"
									/>
									<FormError
										error={
											errors?.adresse
										}
									/>
								</div>
								<div className="col-md-6">
									<label
										htmlFor="pays"
										className="form-label form-label-modal-custom"
									>
										Pays
									</label>
									<CountrySelector
										onChange={
											handleCountrySelect
										}
										name="pays"
										placeholder="Selectionner un pays"
										value={country}
										containerClass=" select-container"
									/>
								</div>
								<div className="col-md-6">
									<AdminInput
										label="Ville"
										id="ville"
										type="text"
										placeholder="Ville"
										{...register(
											"ville"
										)}
										error={
											errors?.ville
										}
									/>
								</div>
								<div className="col-md-6">
									<AdminInput
										label="Rue"
										id="rue"
										type="text"
										placeholder="Rue"
										{...register("rue")}
										error={errors?.rue}
									/>
								</div>
								<div className="col-md-6">
									<AdminInput
										label="Code postal"
										id="code_postal"
										type="text"
										minLength={5}
										maxLength={5}
										min={0}
										placeholder="Code postal"
										{...register(
											"code_postal"
										)}
										error={
											errors?.code_postal
										}
										inputMode="text"
									/>
								</div>
								<div className="col-md-6">
									<AdminInput
										label="Photo de profil"
										id="avatar"
										accept="image/*"
										type="file"
										onChange={
											handleChangePicture
										}
									/>
									<PreviewImage
										image={picture}
									/>
								</div>
							</div>
							<div className="container-btn-modal flex-r gap-3 mt-4">
								<button
									type="button"
									className="btn btn-cancelled"
									data-bs-dismiss="modal"
									onClick={() => {
										reset();
										handleReset();
									}}
								>
									Annuler
								</button>
								<BtnSubmit
									isLoading={isLoading}
									label={
										item
											? "Modifier"
											: "Ajouter"
									}
									style={{
										width: "inherit",
									}}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddMedecinModal;
